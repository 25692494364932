/*
  Project: TRR266
  Author: Morten Sassi
 */

import lozad from 'lozad';
import Plyr from 'plyr';

import n2g from './modules/newsletter2go'
import facet from './modules/facet';
import tabs from './modules/tabs';
import trigger from './modules/trigger';
import slider from './modules/slider';
import clipboard from './modules/clipboard';
import comment from './modules/comment';
import microAnim from './modules/microanimations';
import quickMenu from './modules/quick-menu';
import collapse from './modules/collapse';
import trrSwiper from './modules/trr-swiper'
import tooltip from './modules/tooltip'
import search from './modules/search'
import popup from './modules/popup'
import counter from './modules/counter'
import contentPopup from './modules/contentPopup'
import nav from './modules/nav'
import rcb from './modules/real-cookie-banner'

document.addEventListener('DOMContentLoaded', () => {
  n2g()
  facet()
  tabs()
  trigger()
  slider()
  clipboard()
  comment()
  microAnim()
  quickMenu()
  trrSwiper()
  tooltip()
  search();
  popup();
  counter();
  contentPopup();
  nav();
  rcb();

  initializePlyrPlayers();
  initializeLazyLoading();
  initializeCollapsibleTags();
});

function initializePlyrPlayers() {
  const players = Array.from(document.querySelectorAll('.u-player')).map(p => new Plyr(p));
}

function initializeLazyLoading() {
  const imgObserver = lozad('.u-lazy', {

    loaded: (el) => el.classList.add('c-img--is-loaded')
  });

  const observeImages = () => imgObserver.observe();

  observeImages();
  window.addEventListener('facetwp-loaded', observeImages);
  window.addEventListener('afterAjaxSuccess.tribeEvents', observeImages);
}

function initializeCollapsibleTags() {
  collapse('.c-tags.is-collapseable', 'post-tags');
}

