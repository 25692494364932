export default function() {
  const navItemEls = document.querySelectorAll('.c-nav__subitem--with-children');

  if (navItemEls) {
    const headerEl = document.querySelector('.c-header');
    const headerElBox = headerEl.getBoundingClientRect();

    const arr = Array.from(navItemEls);
    for (let i = 0; i < arr.length; i++) {
      const navItemEl = arr[i];
      const box = navItemEl.getBoundingClientRect();

      navItemEl.style.setProperty('--subitem-y-pos', `${box.top - headerElBox.height}px`);
    }
  }
}
