export default function () {
  const rcbBlockerElements = document.querySelectorAll('.rcb-content-blocker');

  if (rcbBlockerElements) {
    Array.from(rcbBlockerElements).forEach((blockerElement) => {
      const plyrElement = blockerElement.parentNode.parentNode;

      if (plyrElement && plyrElement.classList.contains('plyr')) {
        plyrElement.classList.add('has-blocker');
      }
    })
  }
}
