export default function () {
  const searchTriggerElements = document.querySelectorAll('.c-nav__search-trigger');
  const navBurgerElement = document.querySelector('.c-nav__burger');
  const searchbarElement = document.getElementById('searchform');
  const searchbarInput = document.getElementById('s');

  const handleAfterShow = () => {
    searchbarInput.focus()
    searchbarElement.removeEventListener('transitionend', handleAfterShow)
  }

  if (searchTriggerElements) {
    [...searchTriggerElements].forEach(el => {
      el.addEventListener('click', () => {
        // Close maybe open menu
        if (navBurgerElement.classList.contains('is-active')) {
          navBurgerElement.click()
        }

        if (el.classList.contains('is-active')) {
          el.classList.remove('is-active')
          searchbarElement.classList.remove('is-active')
        } else {
          el.classList.add('is-active')
          searchbarElement.classList.add('is-active')

          searchbarElement.addEventListener('transitionend', handleAfterShow)
        }
      })
    })
  }
}
