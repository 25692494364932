export default function() {
  const $trigger = Array.prototype.slice.call(
    document.querySelectorAll('.u-trigger'),
    0,
  );

  // Check if there are any navbar burgers
  if ($trigger.length > 0) {
    // Add a click event on each of them
    $trigger.forEach(el => {
      el.addEventListener('click', () => {
        // Get the target from the "data-target" attribute
        const { target } = el.dataset;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "c-nav__burger" and the "c-nav__menu"
        el.classList.toggle('is-active');
        $target.classList.toggle('is-active');
      });
    });
  }
}
