import {computePosition, flip, shift, offset, autoUpdate} from '@floating-ui/dom';

export default function () {
  const tooltips = document.querySelectorAll('.u-tooltip');

  if (tooltips) {
    tooltips.forEach(tooltip => {
      const trigger = tooltip.querySelector('.u-tooltip__trigger')
      const target = tooltip.querySelector('.u-tooltip__target')
      let visible = false
      let cleanup = null

      async function update() {
        const {x, y} = await computePosition(trigger, target, {
          placement: 'bottom',
          middleware: [flip(), shift({padding: 8}), offset(8)],
        });

        Object.assign(target.style, {
          left: `${x}px`,
          top: `${y}px`,
        });
      }

      function toggleTooltip() {
        if (visible) {
          hideTooltip()
        } else {
          showTooltip()
        }
      }

      function showTooltip() {
        visible = true
        target.style.visibility = 'visible';
        target.style.transform = `translateY(0)`;
        target.style.opacity = `1`;
        update();

        cleanup = autoUpdate(trigger, target, update);
      }

      function hideTooltip() {
        visible = false
        target.style.visibility = '';
        target.style.transform = `translateY(1rem)`;
        target.style.opacity = `0`;

        if (cleanup) {
          cleanup();
          cleanup = null;
        }
      }

      target.querySelector('.u-tooltip__target-close').addEventListener('click', () => {
        hideTooltip()
      });

      [
        ['click', toggleTooltip],
        ['focus', showTooltip],
        ['blur', hideTooltip],
      ].forEach(([event, listener]) => {
        trigger.addEventListener(event, listener);
      })
    })
  }
}
