function addCheckedToAll(facets) {
  const facetNode = facet =>
    document.querySelector(`.facetwp-facet-${facet}`);

  facets.forEach(facet => {
    const allFacet = facetNode(facet).querySelector('.facetwp-checkbox--all');
    const activeFacet = facetNode(facet).querySelector(
      '.facetwp-checkbox.checked',
    );

    if (!activeFacet && allFacet) {
      allFacet.classList.add('checked');
    }
  });
}

function handleProjectCategoryFacets(typeKeys) {
  if (!typeKeys.includes('project_categories')) {
    return false
  }

  const infoElements = document.querySelectorAll('.c-article__sidebar-project-info')
  const currentCatSlug = window.FWP.facets['project_categories'];

  if (infoElements) {
    [...infoElements].forEach(infoElement => {
      infoElement.classList.toggle('show', currentCatSlug.includes(infoElement.dataset.projectCategoryDescription) )
    })
  }
}

export default function() {
  if (typeof window.FWP !== 'undefined') {
    const facetTypes = window.FWP.facet_type;

    if (facetTypes) {
      const facetTypeKeys = Object.keys(facetTypes);
      const checkboxFacets = facetTypeKeys.filter(
        type => facetTypes[type] === 'checkboxes',
      );

      if (checkboxFacets) {
        addCheckedToAll(checkboxFacets);
      }
      handleProjectCategoryFacets(facetTypeKeys)

      window.addEventListener('facetwp-loaded', () => {
        if (checkboxFacets) {
          addCheckedToAll(checkboxFacets);
        }

        handleProjectCategoryFacets(facetTypeKeys)
      })
    }
  }

}
