import A11yDIalog from 'a11y-dialog';

export default function() {
  const dialogElements = document.querySelectorAll('.u-dialog__container');
  const dialogElementArr = Array.from(dialogElements);

  if (dialogElementArr.length > 0) {
    dialogElementArr.forEach((dialogElement) => {
      const trigger = dialogElement.getAttribute('aria-labelledby');
      const triggerEl = document.getElementById(trigger);

      const dialog = new A11yDIalog(dialogElement);

      triggerEl.addEventListener('click', () => dialog.show())

      dialog
        .on('show', () => (document.documentElement.style.overflowY = 'hidden'))
        .on('hide', () => (document.documentElement.style.overflowY = ''))
    })
  }
}
