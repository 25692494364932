async function setDefaultHeight(el, elBox, varName) {
  await document.documentElement.style.setProperty(
    `--${varName}-height`,
    elBox.height,
  );
  await el.setAttribute('data-default-height', elBox.height);
}

const collapse = (selector, varName) => {
  const el = document.querySelector(selector);
  if (el) {
    const elBox = el.getBoundingClientRect();
    setDefaultHeight(el, elBox, varName).then(() => {
      el.classList.add('is-collapsed');
    });
  }
};

export default collapse;
