import {tns} from 'tiny-slider/src/tiny-slider'
import customForEach from '../helper/forEach'

export default function () {
  const $sliders = document.querySelectorAll('.c-slider')
  const mql = window.matchMedia('(max-width: 1023px)')

  function setStyleProps(slideEl, index) {
    const heroMedia = slideEl.querySelector('.c-hero__media')
    const heroContent = slideEl.querySelector('.c-hero__content')
    const textLines = heroContent.querySelectorAll('.c-hero__textline');
    heroContent.style.setProperty('--cta-index', textLines.length + 1)

    const boxes = {
      media: heroMedia.getBoundingClientRect(),
      content: heroContent.getBoundingClientRect(),
    }

    heroContent.style.setProperty('--media-y-offset', boxes.content.height - boxes.media.height + 'px')
  }

  if ($sliders.length > 0) {
    customForEach($sliders, (i, val) => {
      const sliderEl = val
      const {customSettings} = sliderEl.dataset
      const sliderImg = sliderEl.querySelector('.c-img')
      const sliderCtrl = sliderEl.querySelector('.c-slider__controls')


      let settings = {
        container: sliderEl.querySelector('.c-slider__items'),
        mode: 'gallery',
        controls: false,
        nav: false,
        autoplayButtonOutput: false,
      }

      if (customSettings) {
        const formattedCustomSettings = JSON.parse(customSettings)
        settings = {...settings, ...formattedCustomSettings}
      }

      const slider = tns(settings)

      if (slider && sliderCtrl) {
        const arrowPrev = sliderCtrl.querySelector('.c-slider__arrow--prev')
        const arrowNext = sliderCtrl.querySelector('.c-slider__arrow--next')
        const sliderCount = sliderCtrl.querySelector('.c-slider__current')
        const {container} = slider.getInfo()

        Array.from(container.children).forEach((child, index) => {
          setStyleProps(child, index)
        })

        const onIndexChange = () => {
          const {displayIndex} = slider.getInfo()

          sliderCount.innerText = `0${displayIndex}`
        }

        arrowNext.addEventListener('click', () => {
          slider.pause()
          slider.goTo('next')

          setTimeout(() => {
            slider.play()
          }, 5000)
        })


        arrowPrev.addEventListener('click', () => {
          slider.pause()
          slider.goTo('prev')

          setTimeout(() => {
            slider.play()
          }, 5000)
        })

        slider.events.on('indexChanged', onIndexChange)

        if (sliderCtrl && mql.matches) {
          sliderCtrl.style.top = `${sliderImg.getBoundingClientRect().height /
          2}px`
        }
      }
    })
  }
}
