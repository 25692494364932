import ClipboardJS from 'clipboard';

export default function() {
  const clipboard = new ClipboardJS('.c-clipboard__btn');

  clipboard.on('success', e => {
    const btn = e.trigger;
    btn.classList.add('is-copied');

    setTimeout(() => {
      btn.classList.remove('is-copied');
      e.clearSelection();
    }, 900);
  });
}
