export default function () {
  const elements = document.querySelectorAll('.c-block--popup')
  const localStorageEntry = localStorage.getItem('trr_popups_hidden')

  if (elements) {
    [...elements].forEach((el) => {
      const removeEl = () => {
        el.remove()
        el.removeEventListener('transitionend', removeEl)
      }
      const closeBtn = el.querySelector('.c-block__close')
      const {showOnlyOnce} = el.dataset

      closeBtn.addEventListener('click', () => {
        el.classList.add('dismiss')

        if (showOnlyOnce === '1') {
          let items = []

          if (localStorageEntry) {
            items = JSON.parse(localStorageEntry)
          }

          if (!items.includes(el.id)) {
            items.push(el.id)
          }

          localStorage.setItem(`trr_popups_hidden`, JSON.stringify(items))
        }
      })

      el.addEventListener('transitionend', removeEl)

      if (showOnlyOnce === '1') {
        if (localStorageEntry && JSON.parse(localStorageEntry).includes(el.id)) {
          el.classList.add('dismiss')
        }
      }
    })
  }
}
