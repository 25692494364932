import Gumshoe from 'gumshoejs';
import SmoothScroll from 'smooth-scroll';
import customForEach from '../helper/forEach';

function prepareAnchors() {
  const anchors = document.querySelectorAll('[data-trr-page-anchor]');
  const anchorsArr = Array.from(anchors);

  if (anchorsArr.length > 0) {
    const quickMenu = document.createElement('ul');
    quickMenu.id = 'quick-menu';
    quickMenu.classList.add('c-quick-menu')
    quickMenu.setAttribute('aria-hidden', true);

    anchorsArr.forEach((anchorEl, anchorElIndex) => {
      const { anchorTitle } = anchorEl.dataset;

      const quickMenuListItem = document.createElement('li');
      const quickMenuLinkItem = document.createElement('a');
      const quickMenuLinkItemSpan = document.createElement('span');

      quickMenuLinkItem.href = `#${anchorEl.id}`;
      quickMenuLinkItem.classList.add('c-quick-menu__link');
      quickMenuLinkItemSpan.innerText = anchorTitle;

      quickMenuLinkItem.appendChild(quickMenuLinkItemSpan);
      quickMenuListItem.appendChild(quickMenuLinkItem);
      quickMenu.appendChild(quickMenuListItem);
    })

    document.querySelector('main.o-wrapper').prepend(quickMenu);
  }
}

export default function() {
  prepareAnchors();

  const smoothScrollTriggers = document.querySelectorAll(
    '.c-quick-menu__link',
  );

  const quickMenuEl = document.getElementById('quick-menu')

  if (smoothScrollTriggers) {
    customForEach(smoothScrollTriggers, (i, trigger) => {
      const scroll = new SmoothScroll();
      const smoothScrollTrigger = trigger;
      const { hash } = smoothScrollTrigger;
      const smoothScrollTarget = document.querySelector(hash);
      const OPTIONS = {
        speed: 300,
        easing: 'easeOutCubic',
      };

      smoothScrollTrigger.addEventListener('click', () => {
        scroll.animateScroll(
          smoothScrollTarget,
          smoothScrollTrigger,
          OPTIONS,
        );
      });
    });

    if (quickMenuEl) {
      const spy = new Gumshoe('#quick-menu a', {
        navClass: 'is-active',
        contentClass: 'is-active',
        offset: function () {
          return document.querySelector('header').getBoundingClientRect().height;
        }
      });

      spy.setup();
    }
  }
};

