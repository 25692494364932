import Swiper, { Navigation, Scrollbar } from 'swiper'

export default function() {
  const swiperElements = document.querySelectorAll('.trr-swiper');

  if (swiperElements) {
    swiperElements.forEach(swiperEl => {
      const collapseTrigger = swiperEl.querySelector('.trr-swiper__collapse-trigger');
      let options;
      const additionalOptions = swiperEl.dataset.sliderOptions
      const basicOptions = {
        modules: [Navigation, Scrollbar],
        slidesPerView: 'auto',
        spaceBetween: 16,
        breakpoints: {
          1024: {
            spaceBetween: 24,
          }
        },
        scrollbar: {
          el: swiperEl.querySelector('.trr-swiper__scrollbar'),
          draggable: true,
          dragClass: 'trr-swiper__scrollbar-drag'
        },
        navigation: {
          nextEl: swiperEl.querySelector('.trr-swiper__button--next'),
          prevEl: swiperEl.querySelector('.trr-swiper__button--prev'),
        },
      }

      if (additionalOptions) {
        options = {...basicOptions, ...JSON.parse(additionalOptions)}
      } else {
        options = basicOptions
      }

      const slider = new Swiper(swiperEl.querySelector('.trr-swiper__container'), options)
      if (collapseTrigger) {
        let destroyed = false
        let triggerText = collapseTrigger.innerHTML
        collapseTrigger.addEventListener('click', () => {
          if (destroyed) {
            destroyed = false
            slider.init()
            triggerText = 'Zur Galerie wechseln'
          } else {
            destroyed = true
            slider.destroy(false, true)
            triggerText = 'Alle anzeigen'
          }
        })

        collapseTrigger.innerHTML = triggerText
      }
    })
  }
}
