import { CountUp } from 'countup.js'

export default function() {
  const elements = document.querySelectorAll('.c-counter');
  const elementsArr = Array.from(elements);

  if (elementsArr.length > 0) {
    elementsArr.forEach(element => {
      const { counterValue, counterPrefix, counterSuffix, counterDuration } = element.dataset;

      const opts = {
        enableScrollSpy: true,
        duration: Number(counterDuration),
      };

      if (counterPrefix) {
        opts.prefix = counterPrefix
      }
      if (counterSuffix) {
        opts.suffix = counterSuffix
      }

      const counterInstance = new CountUp(element, counterValue, opts);

      counterInstance.start();
    })
  }
};
