import customForEach from '../helper/forEach';

export default function() {
  const propositionIcons = document.querySelectorAll('.c-proposition__icon');

  if (propositionIcons.length > 0) {
    customForEach(propositionIcons, (i, icon) => {
      const iconBefore = icon.querySelector('.c-proposition__icon-before');
      const iconAfter = icon.querySelector('.c-proposition__icon-after');
      let plusOrMinus = Math.random() < 0.5 ? -1 : 1;

      [iconBefore, iconAfter].forEach((el, eli) => {
        const node = el;
        if (eli === 1) {
          plusOrMinus = plusOrMinus === 1 ? -1 : 1;
        }
        node.style = `transform: translate(${plusOrMinus}rem, ${plusOrMinus}rem)`;
      });
    });
  }
};
