import customForEach from '../helper/forEach';

export default function () {
  const commentForms = document.querySelectorAll('.c-form--comment');
  const comments = document.querySelectorAll('.c-comment');
  const activeClass = node => node.classList.add('is-active');

  if (commentForms.length > 0) {
    customForEach(commentForms, (i, form) => {
      const commentForm = form;
      const commentMessage = commentForm.querySelector(
        '.c-form__input--message',
      );

      commentMessage.addEventListener('click', () => {
        activeClass(commentForm);
      });
    });
  }

  if (comments.length > 0) {
    customForEach(comments, (i, c) => {
      const commentReplyTrigger = c.querySelector(
        '.c-comment__reply-trigger',
      );

      if (commentReplyTrigger) {
        const { target } = commentReplyTrigger.dataset;
        const commentReply = document.getElementById(target);
        const commentCancelReply = commentReply.querySelector(
          '.c-form__cancel-reply',
        );

        commentReplyTrigger.addEventListener('click', () => {
          activeClass(commentReply);
        });

        commentCancelReply.addEventListener('click', () => {
          commentReply.classList.remove('is-active');
        });
      }
    });
  }
};

