import jQuery from 'jquery'

const newsletterElements = [
  {
    id: 'n2g-content',
    key: 'ye8juwgz-us26bfuf-16ek',
  },
  {
    id: 'n2g-footer',
    key: 'ye8juwgz-rjmspeqv-1aly',
  }
]

export default function() {
  newsletterElements.forEach(item => {
    const el = document.getElementById(item.id)

    if (el) {
      jQuery(function () {
        var formNotice = jQuery(`#${item.id} .c-form__notice`);
        var formNoticeMessage = jQuery('.c-notice__message', formNotice);
        var formNoticeSuccess = jQuery('.c-notice__message--success', formNotice);
        var formNoticeNoValidMail = jQuery('.c-notice__message--no-valid-email', formNotice);
        var formNoticeAlreadyReg = jQuery('.c-notice__message--already-registered', formNotice);
        var formNoticeError = jQuery('.c-notice__message--error', formNotice);

        jQuery(`#${item.id}`).on('submit', function (e) {
          e.preventDefault();

          n2g('create', item.key);

          var formSubmitBtn = jQuery('.c-form__input--submit', this);
          var recipient = {
            email: jQuery('input[type=email]', this).val()
          }
          formSubmitBtn.addClass('is-loading');

          n2g(
            'subscribe:send', {
              recipient: recipient
            },
            function (data) {
              formSubmitBtn.removeClass('is-loading');
              formNoticeMessage.removeClass('is-active');
              if (data.status === 201) {
                formNoticeSuccess.addClass('is-active');
              } else if(data.status === 200) {
                if (data.value[0].result.error.recipients.invalid.length) {
                  formNoticeNoValidMail.addClass('is-active');
                }
                formNoticeAlreadyReg.addClass('is-active');
              } else {
                formNoticeError.addClass('is-active');
              }
            },
            function () {
              formSubmitBtn.removeClass('is-loading');
              formNoticeMessage.removeClass('is-active');
              formNoticeError.addClass('is-active');
            }
          );
        });
      })
    }
  })
}
